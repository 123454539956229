/* eslint-disable no-template-curly-in-string */

import { isProduction } from 'app-config'

export enum FEATURE_TYPES {
  WPP_DUPLICATE_VERSION = 'WPP_DUPLICATE_VERSION',
  WPP_TEST_LAUNCH = 'WPP_TEST_LAUNCH',
  WPP_ENABLING_NOT_PUBLISHED_APP_AVAILABILITY = 'WPP_ENABLING_NOT_PUBLISHED_APP_AVAILABILITY',
}

type FEATURE_TYPES_NAMES = keyof typeof FEATURE_TYPES extends `WPP_${infer P}` ? P : never
export const featureFlags: Record<FEATURE_TYPES, `\${FEATURE_FLAG_${FEATURE_TYPES_NAMES}}`> = {
  WPP_DUPLICATE_VERSION: '${FEATURE_FLAG_DUPLICATE_VERSION}',
  WPP_TEST_LAUNCH: '${FEATURE_FLAG_TEST_LAUNCH}',
  WPP_ENABLING_NOT_PUBLISHED_APP_AVAILABILITY: '${FEATURE_FLAG_ENABLING_NOT_PUBLISHED_APP_AVAILABILITY}',
} as const

const featureFlagsConfig = Object.keys(FEATURE_TYPES).reduce((acc, type) => {
  const flag = isProduction ? featureFlags[type as FEATURE_TYPES] : process.env[`REACT_APP_${type}`]
  return { ...acc, [type]: flag }
}, {} as Record<FEATURE_TYPES, string>)

export default featureFlagsConfig
